import React, { useMemo } from "react"

import PropTypes from "prop-types"
import classNames from 'classnames/bind'
import styles from '@styles/components/btn_box.module.scss'

import TransitionAnimationLink from '@comp/transition_animation_link'
import IcnTriangle from '@comp/atom/icn_triangle'

const cx = classNames.bind(styles);
const propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

const BtnBox = ({
  children,
  to,
  href,
  target,
  onClick,
  disabled
}) => {
  const classNameWrap = cx({
    wrap: true
  })
  const blank = {
    target: target,
    rel: target === '_blank' ? 'noopener noreferrer' : null
  }
  const innerElm = useMemo( () => {
    return (
      <>
        <span className={styles.inner}>
          <span className={styles.label}>{children}</span>
          <span className={styles.icn}><IcnTriangle /></span>
        </span>
      </>
    )
  },[children])

  return (
    <>
      {
        to ? (
          <TransitionAnimationLink 
            to={to} 
            className={classNameWrap}
            onClick={onClick}
          >
            {innerElm}
          </TransitionAnimationLink>
        ) : (
          onClick ? (
            <button
              onClick={onClick}
              className={classNameWrap}
              disabled={disabled ? true: null}
            >
              {innerElm}
            </button>
          ): (
            <a 
              href={href}
              className={classNameWrap}
              {...blank}
            >
              {innerElm}
            </a>
          )
        )
      }
    </>
  )
}

BtnBox.propTypes = propTypes
export default BtnBox
