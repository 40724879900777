import React, { useCallback, useMemo, useState} from "react"
import { useForm } from "react-hook-form"
import {animateScroll} from 'react-scroll'

import axios from 'axios'

import styles from '@styles/pages/contact.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'

import PageTitle from '@comp/page_title'
import IcnTriangle from '@comp/atom/icn_triangle'
import BtnBox from '@comp/btn_box'

import contactSubject from '@const/contact_subject_list'
import { CONTACT_API } from '@const/api_list'

const scrollOption = {
  smooth: true,
  offset: -100,
  isDynamic: true,
  duration: 500
}

// markup
const ContactPage = ({ location }) => {

  const [modeConfirm, setModeConfirm] = useState(false)
  const [modeComplete, setModeComplete] = useState(false)
  const [modeSubmitError, setModeSubmitError] = useState(false)
  const [isSending, setIsSending] = useState(false);

  const { 
    register,
    watch,
    trigger,
    getValues,
    errors,
    formState: { isValid, touched },
  } = useForm({
    mode: 'onBlur'
  });

  const optionList = useMemo( () => {
    return contactSubject.map( (itm,index) => {
      return <option key={index} value={itm.label}>{itm.label}</option>
    })
  },[])

  const values = getValues()
  const watchNotReq = watch([
    'company_name'
  ])

  const onClickConfirm = useCallback( async (e) => {
    e.preventDefault()
    const result = trigger()
    if(result) {
      setModeConfirm(true)
      animateScroll.scrollToTop(scrollOption)
    }
  },[trigger])

  const onClickFix = useCallback( (e) => {
    e.preventDefault()
    setModeConfirm(false)
    animateScroll.scrollToTop(scrollOption)
  },[])

  const onSubmitError = useCallback( () => {
    setModeSubmitError(true)
    setModeConfirm(false)
    animateScroll.scrollToTop(scrollOption)
  },[])

  const onSubmit = useCallback( async (data) => {
    setIsSending(true)
    await axios.post(CONTACT_API,data,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    }).then( (result) => {
      if(result.data.result === 'success') {
        setModeSubmitError(false)
        setModeComplete(true)
      } else {
        throw Error('post error')
      }
      setIsSending(false)
      animateScroll.scrollToTop(scrollOption)
    }).catch( (error) => {
      onSubmitError()
    })
  },[onSubmitError])

  const onClickSubmit = useCallback( async (e) => {
    e.preventDefault()
    const result = await trigger()
    if(result) {
      onSubmit(values)
    }
  },[onSubmit, trigger, values])

  return (
    <Layout location={location} pageName="contact">
      <SEO
        title={`CONTACT`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            label='お問い合わせ'
            labelEn='CONTACT'
          />

          
          {
            modeSubmitError ? (
              <div className={styles.error_box}>
                <p>送信に失敗しました、ネットワークの状態をお確かめのうえ再度お試しください。</p>
              </div>
            ) : ''
          }

          {
            modeComplete ?
              (
                <>
                  <div className={styles.complete}>
                    <h2 className={styles.complete__title}>お問い合わせありがとうございました。</h2>
                    <p className={styles.complete__txt}>
                      通常2〜３営業日以内にご連絡さしあげますので、今しばらくお待ちください。<br />弊社から連絡がない場合には、頂いたご連絡先に誤りがあった可能性もございますので、<br />お手数ですが今一度お問い合わせお願いいたします。
                    </p>
                    <div className={styles.complete__btn}>
                      <BtnBox
                        to='/'
                      >
                        <span className={styles.complete__btn__txt}>TOPへ</span>
                      </BtnBox>
                    </div>
                  </div>
                </>
              ):
              (
                <>
                  {
                    modeConfirm ? (
                      <h2 className={styles.form__title}>こちらの内容でお問い合わせします。よろしいですか？</h2>
                    ): ''
                  }
                  <div className={styles.form}>
                    <form className={styles.form__body}>
                      <div className={styles.form__row}>
                        <div className={styles.form__label}>
                          <label htmlFor="name" aria-labelledby="name" className={styles.label}>
                            <span className={styles.label__txt}>お名前</span><span className={styles.label__req}>必須</span></label>
                        </div>
                        <div className={styles.form__box}>
                          <div className={styles.form__input}>
                            <input ref={register({
                              required: 'お名前は必須です',
                            })} defaultValue="" id="name" name="name" type="text" className={styles.input} placeholder="お名前をご記載ください" aria-required="true" aria-invalid={errors?.name ? true : false} data-inputed={touched?.name && !!values?.name} disabled={modeConfirm} />
                          </div>
                          { 
                            errors.name ? (
                              <div className={styles.input__error}>{errors.name?.message}</div>  
                            ) : ''
                          }
                        </div>
                      </div>
                      <div className={styles.form__row}>
                        <div className={styles.form__label}>
                          <label htmlFor="company_name" aria-labelledby="company_name" className={styles.label}>
                            <span className={styles.label__txt}>会社名</span></label>
                        </div>
                        <div className={styles.form__box}>
                          <div className={styles.form__input}>
                            <input ref={register} defaultValue="" id="company_name" name="company_name" type="text" className={styles.input} placeholder="会社名をご記載ください" aria-invalid={errors.company_name ? true : false} data-inputed={touched?.company_name && !!watchNotReq?.company_name} disabled={modeConfirm} />
                          </div>
                        </div>
                      </div>
                      <div className={styles.form__row}>
                        <div className={styles.form__label}>
                          <label htmlFor="email" aria-labelledby="email" className={styles.label}>
                            <span className={styles.label__txt}>メールアドレス</span>
                            <span className={styles.label__req}>必須</span></label>
                        </div>
                        <div className={styles.form__box}>
                          <div className={styles.form__input}>
                            <input ref={register({
                              required: 'メールアドレスは必須です',
                              pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: '正しいメールアドレスを入力してください'
                              }
                            })} defaultValue="" id="email" name="email" type="email" className={styles.input} placeholder="ご連絡用のメールアドレスをご記載ください" aria-required="true" aria-invalid={errors.email ? true : false} data-inputed={touched?.email && !!values?.email} disabled={modeConfirm} />
                          </div>
                          { 
                            errors.email ? (
                              <div className={styles.input__error}>{errors.email?.message}</div>  
                            ) : ''
                          }
                        </div>
                      </div>
                      <div className={styles.form__row}>
                        <div className={styles.form__label}>
                          <label htmlFor="tel" aria-labelledby="tel" className={styles.label}>
                            <span className={styles.label__txt}>電話番号</span></label>
                        </div>
                        <div className={styles.form__box}>
                          <div className={styles.form__input}>
                            <input ref={register({
                              maxLength: 15,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: '半角数字でご入力ください'
                              }
                            })} defaultValue="" id="tel" name="tel" type="tel" className={styles.input} placeholder="半角数字ハイフンなし" aria-invalid={errors.tel ? true : false} maxLength="15" data-inputed={touched?.tel && !!values?.tel} disabled={modeConfirm} />
                          </div>
                          { 
                            errors.tel ? (
                              <div className={styles.input__error}>{errors.tel?.message}</div>  
                            ) : ''
                          }
                        </div>
                      </div>
                      <div className={styles.form__row}>
                        <div className={styles.form__label}>
                          <label htmlFor="subject" aria-labelledby="subject" className={styles.label}>
                            <span className={styles.label__txt}>件名</span><span className={styles.label__req}>必須</span></label>
                        </div>
                        <div className={styles.form__box}>
                          <div className={styles.form__input}>
                            <div className={styles.select}>
                              <select ref={register({
                                required: 'サービスを選択してください'
                              })} id="subject" name="subject" defaultValue="" className={styles.select__box} aria-required="true" aria-invalid={errors.subject ? true : false} data-inputed={touched?.subject && !!values?.subject} disabled={modeConfirm}>
                                <option value="" disabled>サービスを選択してください</option>
                                {optionList}
                              </select>
                              <span className={styles.select__icn} ><IcnTriangle /></span>
                            </div>
                          </div>
                          { 
                            errors.subject ? (
                              <div className={styles.input__error}>{errors.subject?.message}</div>  
                            ) : ''
                          }
                        </div>
                      </div>
                      <div className={styles.form__row}>
                        <div className={styles.form__label}>
                          <label htmlFor="subject" className={styles.label}>
                            <span className={styles.label__txt}>お問い合わせ内容</span><span className={styles.label__req}>必須</span></label>
                        </div>
                        <div className={styles.form__box}>
                          <div className={styles.form__input}>
                            <textarea ref={register({
                              required: 'お問い合わせ内容を入力してください'
                            })} defaultValue="" id="body" name="body" className={styles.textarea} maxLength="1000" placeholder="お問い合わせ内容をご記載ください"aria-invalid={errors?.body ? true : false} data-inputed={touched.body && !!values?.body} disabled={modeConfirm}></textarea>
                          </div>
                          { 
                            errors.body ? (
                              <div className={styles.input__error}>{errors.body?.message}</div>  
                            ) : ''
                          }
                        </div>
                      </div>
        
                      <div className={styles.submit}>
                        {
                          modeConfirm ? (
                            <div className={styles.submit__inner}>
                              <button onClick={onClickFix} className={styles.submit__fix}>
                                <span className={styles.submit__fix__txt}>修正する</span>                                
                              </button>
                              <div className={styles.submit__regist}>
                                <BtnBox
                                  onClick={onClickSubmit}
                                  disabled={!isValid && isSending}
                                >
                                  <span className={styles.submit__txt}>OK</span>
                                </BtnBox>
                              </div>
                            </div>
                          ): (
                            <div className={styles.submit__confirm}>
                              <BtnBox
                                onClick={onClickConfirm}
                                disabled={!isValid}
                              >
                                <span className={styles.submit__txt}>送信</span>
                              </BtnBox>
                            </div>
                          )
                        }
                      </div>
                    </form>
                  </div>
                </>
              )
          }
        </div>
      </main>
    </Layout>
  )
}

export default ContactPage
