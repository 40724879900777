export default [
  {
    id: 1,
    label: '未来日本酒店 / 未来酒店'
  },
  {
    id: 2,
    label: 'YUMMY SAKE'
  },
  {
    id: 4,
    label: 'MIRAI SAKE INTERNATIONAL'
  },
  {
    id: 5,
    label: 'MIRAI SAKE BREWING'
  },
  {
    id: 6,
    label: '酒造好適米生産事業'
  },
  {
    id: 7,
    label: '未来酒蔵承継機構'
  },
  {
    id: 8,
    label: 'MIRAI SAKE CONSULTING'
  },
  {
    id: 9,
    label: '採用・その他'
  }
]